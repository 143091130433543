import React from "react";
import Footer from "./Footer";
// import Home from "../Components/Home";
import Reports from "../Components/Reports/Reports";
import TakeOrder from "../Components/TakeOrder/TakeOrder";
import Orders from "../Components/Orders/Orders";
import ItemReports from "../Components/ItemReports";
import CategoryReports from "../Components/CategoryReports";
import BillReports from "../Components/BillReports";
import ExecutiveReports from "../Components/ExecutiveReports";
import TodaysReport from "../Components/TodaysReport";
class ShopDash extends React.Component {
  constructor(props) {
    super();
    this.state = {
      home: true,
      TakeOrder: false,
      orders: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
    };
  }
  showHome = (e) => {
    e.preventDefault();
    this.setState({
      home: true,
      TakeOrder: false,
      orders: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
    });
  };
  showTakeOrder = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      TakeOrder: true,
      orders: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
    });
  };
  showOrders = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      TakeOrder: false,
      orders: true,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
    });
  };
  showItemReports = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      TakeOrder: false,
      orders: false,
      itemReports: true,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
    });
  };
  showCategoryReports = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      TakeOrder: false,
      orders: false,
      itemReports: false,
      categoryReports: true,
      billReports: false,
      execReports: false,
      hourlyReports: false,
    });
  };
  showBillReports = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      TakeOrder: false,
      orders: false,
      itemReports: false,
      categoryReports: false,
      billReports: true,
      execReports: false,
      hourlyReports: false,
    });
  };
  showExecReports = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      TakeOrder: false,
      orders: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: true,
      hourlyReports: false,
    });
  };
  showHourlyReports = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      TakeOrder: false,
      orders: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: true,
    });
  };
  render() {
    const userCheck = window.localStorage.getItem("access");
    return (
      <div className="page-content d-flex align-items-stretch">
        <nav className="side-navbar">
          <div className="sidebar-header d-flex align-items-center">
            <div className="avatar">
              <img
                src="img/brand_logo.jpg"
                alt="..."
                className="img-fluid rounded-circle"
              />
            </div>
            <div className="title">
              <h1 className="h4">{JSON.parse(userCheck).name}</h1>
              <p>{JSON.parse(userCheck).role}</p>
            </div>
          </div>
          <ul className="list-unstyled">
            <li
              onClick={this.showHome}
              className={this.state.home ? "active" : null}
            >
              <a href="#/">
                <i className="icon-home"></i>Home
              </a>
            </li>
            <li
              onClick={this.showTakeOrder}
              className={this.state.TakeOrder ? "active" : null}
            >
              <a href="#/">
                <i className="icon-grid"></i>Take Order
              </a>
            </li>
            <li
              onClick={this.showOrders}
              className={this.state.orders ? "active" : null}
            >
              <a href="#/">
                <i className="icon-grid"></i>Orders
              </a>
            </li>
            <li>
              <a
                href="#exampledropdownDropdown5"
                aria-expanded="false"
                data-toggle="collapse"
              >
                <i className="fa fa-file-text"></i>Reports
              </a>
              <ul
                id="exampledropdownDropdown5"
                className="collapse list-unstyled "
              >
                {/* <li
                  onClick={this.showReports}
                  className={this.state.reports ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-bar-chart"></i>Analytics
                  </a>
                </li> */}
                <li
                  onClick={this.showItemReports}
                  className={this.state.itemReports ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>Item Wise Reports
                  </a>
                </li>
                <li
                  onClick={this.showCategoryReports}
                  className={this.state.categoryReports ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-list-alt"></i>Category Reports
                  </a>
                </li>
                <li
                  onClick={this.showBillReports}
                  className={this.state.billReports ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>Bill Wise Reports
                  </a>
                </li>
                <li
                  onClick={this.showExecReports}
                  className={this.state.execReports ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>Executive Reports
                  </a>
                </li>
                <li
                  onClick={this.showHourlyReports}
                  className={this.state.hourlyReports ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>Hourly Reports
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <span className="heading"></span>
        </nav>
        <div className="content-inner">
          {this.state.home ? <Reports /> : null}
          {this.state.TakeOrder ? <TakeOrder /> : null}
          {this.state.orders ? <Orders /> : null}
          {this.state.itemReports ? <ItemReports /> : null}
          {this.state.categoryReports ? <CategoryReports /> : null}
          {this.state.billReports ? <BillReports /> : null}
          {this.state.execReports ? <ExecutiveReports /> : null}
          {this.state.hourlyReports ? <TodaysReport /> : null}
          <Footer />
        </div>
      </div>
    );
  }
}
export default ShopDash;
